let videos = [
    {
      titel: "Presentation av Basmati Karoon riset",
      info: "Här kommer en kort video om vårt ris",
      videokod: "GY4m5q5r0cw",
    },
    {
        titel: "Presentation av Basmati Karoon riset",
        info: "Här kommer en kort video om vårt ris",
        videokod: "pecwaHBHG7M",
    },
    {
        titel: "Presentation av Basmati Karoon riset",
        info: "Här kommer en kort video om vårt ris",
        videokod: "BkFiYCXqIgc",
    },
    {
        titel: "Presentation av Basmati Karoon riset",
        info: "Här kommer en kort video om vårt ris",
        videokod: "6iQ6kv1LCaY",
    },
  ];
  
  export default videos;
  